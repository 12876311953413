import { useWeb3React } from '@web3-react/core'
import { useState, useContext, useEffect, useCallback } from 'react'
import { StoreAction, StoreContext } from 'web/lib/store'
import { GawdsContractFactory, provider as getEthProvider } from 'web/lib/contract'
import { TamaGawdtchiManager } from 'web/lib/TamaGawdtchiManager'
import usePoller from 'web/hooks/usePoller'
import usePolygonForwarder from './usePolygonForwarder'
import useMyBoons from 'web/hooks/useMyBoons'
import useSWR, { useSWRConfig } from 'swr'
import { CacheKey } from 'web/lib/cache-keys'
import { TamiGAWDtchi } from 'web/types/ethers-contracts'
import usePolygonNonceManager from './usePolygonNonceManager'

// Used for faking chain blocks for tamagawdtchi simulations
export default function useTamagawdtchi(gawdId: number): TamaGawdtchiManager {
  const { library, account } = useWeb3React()
  const gawchiContract: TamiGAWDtchi = GawdsContractFactory.get("TamiGAWDtchi") as TamiGAWDtchi
  const { polygonForwarderSubmit } = usePolygonForwarder()
  const { mutate } = useSWRConfig()

  const [ui, updateUI] = useState<number>(0)
  const gawdtchiPoller = usePoller(1000 * 60 * 1) // update every minute

  const {
    gawdtchis: [gawdtchis, updateGawdtchis],
  } = useContext(StoreContext)

  const { localNonce } = usePolygonNonceManager()

  const myBoons = useMyBoons()

  const gawdtchi = gawdtchis.gawds.length > 0 ? gawdtchis.gawds.filter(g => g.gawdId() == gawdId)[0] : null

  // Load in gawchi block stats
  const { data: cooldowns } = useSWR(CacheKey.gawchiCooldownStats(), async () => {
    const [cower, marvel, worship, sacrifice, resurrect, death] = await Promise.all([
      gawchiContract.getCowerCooldownBlocks(),
      gawchiContract.getMarvelCooldownBlocks(),
      gawchiContract.getWorshipCooldownBlocks(),
      gawchiContract.getSacrificeCooldownBlocks(),
      gawchiContract.getResurrectCooldownBlocks(),
      gawchiContract.getBlocksUntilDeath(),
    ])
    return {
      cower, marvel, worship, sacrifice, resurrect, death
    }
  }, {
    refreshInterval: 0 // never refresh
  })

  const fetchGawchiStats = async () => {
    return {
      stats: await gawchiContract.getAllStats(gawdId),
      lastUpdate: new Date()
    }
  }

  const { data: gawchiStats } = useSWR(CacheKey.gawchiStats(gawdId), fetchGawchiStats, {
    refreshInterval: 1000 * 60 * 15
  })

  useEffect(() => {
    fetchGawdtchiData()
  }, [gawdtchiPoller, gawdId, myBoons, cooldowns, gawchiStats, account, localNonce])

  // Clear cache whenever an action is completed
  const onActionComplete = async () => {
    // Because this component can get unmounted, need to refetch data and stub
    // => https://github.com/vercel/swr/discussions/734
    const data = await fetchGawchiStats()
    await mutate(CacheKey.gawchiStats(gawdId), data)
  }

  const fetchGawdtchiData = async () => {
    if ( !cooldowns || !gawchiStats) {
      return;
    }

    let gawchi: TamaGawdtchiManager = gawdtchi
    if (gawchi?.gawdId() == null) {
      gawchi = new TamaGawdtchiManager({
        gawdId: gawdId,
        library: library,
        contract: gawchiContract,
        gawchiReducer: updateGawdtchis,
        onActionComplete: onActionComplete
      })
    }

    gawchi.props.polygonForwarderSubmit = polygonForwarderSubmit
    gawchi.updateCooldownBlocks(cooldowns)
    gawchi.updateStats(gawchiStats.stats, gawchiStats.lastUpdate)

    updateGawdtchis([StoreAction.Update, gawchi])
    updateUI(ui + 1) // Dumb hack to get the UI to actually update. Dont know why updateGawdtchis doesnt do that?
  }

  return gawdtchi;
}

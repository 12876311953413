import 'web/lib/string-utils'
import { ContractType } from 'web/lib/contract'
import { StoreAction } from './store'
import { Dispatch } from 'react'
import { TamiGAWDtchi } from 'web/types/ethers-contracts'
import { TransactionReceipt } from "@ethersproject/abstract-provider"
import { BigNumber } from 'ethers'
import { TokenData } from 'web/hooks/useWalletTokens'
import { captureException } from '@sentry/nextjs'
import { GawdTransactionReceipt, TransactionError } from 'web/hooks/usePolygonForwarder'

export enum GawchiAction {
  Worship = 'worship',
  Cower = 'cower',
  Sacrifice = 'sacrifice',
  Marvel = 'marvel',
  Resurrect = 'resurrect',
}

export class GawdtchiProps {
  public gawdId: number
  public library: any
  public contract: TamiGAWDtchi
  public onActionComplete?: Function // called whenever an action completes successfully
  public polygonForwarderSubmit?: (contractType: ContractType, command: string, args: any[]) => Promise<GawdTransactionReceipt | TransactionError> // passed into this class bc it's a hook
  public gawchiReducer: Dispatch<[StoreAction, TamaGawdtchiManager]>
}

export class GawchiData {
  public health: number = 0
  public isAlive: boolean = false
  public worshipCooldown: number = 0
  public marvelCooldown: number = 0
  public sacrificeCooldown: number = 0
  public cowerCooldown: number = 0
  public resurrectCooldown: number = 0

  public currentGawdBlock: number = 0
  public lastWorshipBlock: number = 0
  public lastMarvelBlock: number = 0
  public lastSacrificeBlock: number = 0
  public lastCowerBlock: number = 0
}

interface GawchiActionState {
  action: GawchiAction
  loading: boolean
  error?: string
}

export class TamaGawdtchiManager {
  // Hardcoded defaults
  BLOCKS_UNTIL_DEATH = 129600;
  WORSHIP_COOLDOWN = 32400; // 25%
  MARVEL_COOLDOWN = 6480; // 5%
  SACRIFICE_COOLDOWN = 194400; // 150%
  COWER_COOLDOWN = 64800; // 50%
  RESURRECT_COOLDOWN = 43200; // ~24 hours

  private actionStates: GawchiActionState[] = [
    { action: GawchiAction.Cower, loading: false },
    { action: GawchiAction.Marvel, loading: false },
    { action: GawchiAction.Worship, loading: false },
    { action: GawchiAction.Sacrifice, loading: false },
    { action: GawchiAction.Resurrect, loading: false },
  ]
  private lastUpdate: Date = new Date(0)

  public gawdResponseError: string
  public props: GawdtchiProps = new GawdtchiProps()
  public data: GawchiData = new GawchiData()

  constructor(props?: GawdtchiProps) {
    this.props = props
  }

  public gawdId(): number {
    return this.props.gawdId
  }

  public getElapsedTime() {
    return (new Date().getTime() - this.lastUpdate.getTime()) / 1000
  }

  public getElapsedBlocks() {
    return this.getElapsedTime() / 2
  }

  public updateStats(gawchiStats:BigNumber[], lastUpdate: Date) {
    const stats = gawchiStats.map(s => s.toNumber())

    this.lastUpdate = lastUpdate
    this.data.health = stats[0] / 1000
    this.data.worshipCooldown = stats[1] / 1000
    this.data.marvelCooldown = stats[2] / 1000
    this.data.sacrificeCooldown = stats[3] / 1000
    this.data.cowerCooldown = stats[4] / 1000
    this.data.resurrectCooldown = stats[5] / 1000
    this.data.isAlive = this.getHealth() > 0
  }

  public updateCooldownBlocks(cooldowns: any) {
    this.WORSHIP_COOLDOWN = cooldowns.worship
    this.MARVEL_COOLDOWN = cooldowns.marvel
    this.SACRIFICE_COOLDOWN = cooldowns.sacrifice
    this.COWER_COOLDOWN = cooldowns.cower
    this.RESURRECT_COOLDOWN = cooldowns.resurrect
    this.BLOCKS_UNTIL_DEATH = cooldowns.death
  }

  public getAlive(): boolean {
    return this.data.isAlive
  }

  public async loadHealth(): Promise<void> {
    this.data.health = (await this.props.contract.getHealth(this.gawdId())).toNumber() / 1000
    this.getAlive();
    if (!this.data.isAlive) {
      this.data.resurrectCooldown = (await this.props.contract.getResurrectCooldown(this.gawdId())).toNumber() / 1000
    }
  }

  // (number) 0-1.0
  public getHealth(): number {
    if (this.data.health <= 0) {
      return 0
    }
    const elapsedBlocks = this.getElapsedTime() / 2
    const remainingBlocks = this.data.health * this.BLOCKS_UNTIL_DEATH
    const remaining = (remainingBlocks - elapsedBlocks) / this.BLOCKS_UNTIL_DEATH

    return remaining
  }

  getLabelByAction(action) {
    switch (action) {
      case GawchiAction.Worship:
        return "🙏"
      case GawchiAction.Cower:
        return "😨"
      case GawchiAction.Marvel:
        return "❤️"
      case GawchiAction.Sacrifice:
        return "🐐"
      case GawchiAction.Resurrect:
        return "💀"
      default:
        throw new Error(`getActionLabel: Unknown action ${action}`)
    }
  }

  getCooldownByAction(action) {
    let remainingBlocks = 0
    let totalBlocks = 1

    switch (action) {
      case GawchiAction.Worship:
        remainingBlocks = this.data.worshipCooldown * this.WORSHIP_COOLDOWN
        totalBlocks = this.WORSHIP_COOLDOWN
        break
      case GawchiAction.Cower:
        remainingBlocks = this.data.cowerCooldown * this.COWER_COOLDOWN
        totalBlocks = this.COWER_COOLDOWN
        break
      case GawchiAction.Marvel:
        remainingBlocks = this.data.marvelCooldown * this.MARVEL_COOLDOWN
        totalBlocks = this.MARVEL_COOLDOWN
        break
      case GawchiAction.Sacrifice:
        remainingBlocks = this.data.sacrificeCooldown * this.SACRIFICE_COOLDOWN
        totalBlocks = this.SACRIFICE_COOLDOWN
        break
      case GawchiAction.Resurrect:
        remainingBlocks = this.data.resurrectCooldown * (this.RESURRECT_COOLDOWN + this.BLOCKS_UNTIL_DEATH)
        totalBlocks = this.RESURRECT_COOLDOWN
        break
      default:
        console.error("getActionValue: Unknown action", action)
    }
    if (remainingBlocks == 0) {
      return 0
    }
    let blocks = (remainingBlocks - this.getElapsedBlocks()) / totalBlocks

    return blocks > 0 ? blocks : 0
  }

  getCooldownSecondsRemainingByAction(action, secondsPerBlock = 2) {
    let remainingBlocks = 0

    // DRY with getCooldownByAction
    switch (action) {
      case GawchiAction.Worship:
        remainingBlocks = this.data.worshipCooldown * this.WORSHIP_COOLDOWN
        break
      case GawchiAction.Cower:
        remainingBlocks = this.data.cowerCooldown * this.COWER_COOLDOWN
        break
      case GawchiAction.Marvel:
        remainingBlocks = this.data.marvelCooldown * this.MARVEL_COOLDOWN
        break
      case GawchiAction.Sacrifice:
        remainingBlocks = this.data.sacrificeCooldown * this.SACRIFICE_COOLDOWN
        break
      case GawchiAction.Resurrect:
        remainingBlocks = this.data.resurrectCooldown * (this.RESURRECT_COOLDOWN + this.BLOCKS_UNTIL_DEATH)
        break
      default:
        console.error("getActionValue: Unknown action", action)
    }

    let remainingTime = Math.round(remainingBlocks * secondsPerBlock - this.getElapsedTime())

    return remainingTime > 0 ? remainingTime : 0
  }

  async action(action: GawchiAction) {
    switch (action) {
      case GawchiAction.Worship:
        await this.worship()
        break
      case GawchiAction.Cower:
        await this.cower()
        break
      case GawchiAction.Marvel:
        await this.marvel()
        break
      case GawchiAction.Resurrect:
        await this.resurrect()
        break
      case GawchiAction.Sacrifice:
        await this.sacrifice()
        break
      default:
        console.error("action: Unknown action", action)
    }
  }

  async worship() {
    await this.submitAction(GawchiAction.Worship)
  }

  async marvel() {
    await this.submitAction(GawchiAction.Marvel)
  }

  async sacrifice() {
    await this.submitAction(GawchiAction.Sacrifice)
  }

  async cower() {
    await this.submitAction(GawchiAction.Cower)
  }

  async resurrect() {
    await this.submitAction(GawchiAction.Resurrect)
  }

  public setActionState(state: GawchiActionState): void {
    for (let i = 0; i < this.actionStates.length; i++) {
      if (this.actionStates[i].action == state.action) {
        let prevAction = this.actionStates[i].action
        this.actionStates[i] = state

        // Tell the global reducer to update the state of this object
        if (prevAction != state.action) {
          this.props.gawchiReducer([StoreAction.Update, this])
        }
      }
    }
  }

  public getActionState(action: GawchiAction): GawchiActionState {
    return this.actionStates.find(s => s.action == action)
  }

  async submitAction(action: GawchiAction): Promise<void> {
    if (this.getActionState(action).loading) {
      console.warn("This action is still pending", action)
      return
    }

    this.gawdResponseError = null

    // Set this action to loading status
    this.setActionState({
      action: action,
      loading: true,
      error: null
    })

    try {
      const receipt = await this.props.polygonForwarderSubmit("TamiGAWDtchi", action, [this.gawdId()])

      // if success
      if (receipt != null && this.props.onActionComplete) {
        // reload all the data
        this.props.onActionComplete()
      }

      this.setActionState({
        action: action,
        loading: false
      })
    }
    catch (err: any) {
      if (err && err instanceof Error) {
        this.setActionState({
          action: action,
          loading: false,
          error: err.message.toString(),
        })

        const parsed = this.parseMessage(err.message.toString())

        if (parsed) {
          this.gawdResponseError = parsed
        }
        else {
          this.gawdResponseError = `${action} failed`
        }
        return
      }

      this.setActionState({
        action: action,
        loading: false,
        error: err,
      })

      captureException(err)
    }
  }

  parseMessage(message: string): string {
    const match = message.match(/execution reverted: (.*)/)
    if (match) {
      return match[1]
    }
  }
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const EasingFunctions = {
  // no easing, no acceleration
  linear: t => t,
  // accelerating from zero velocity
  easeInQuad: t => t * t,
  // decelerating to zero velocity
  easeOutQuad: t => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
  // accelerating from zero velocity
  easeInCubic: t => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: t => (--t) * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: t => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: t => 1 - (--t) * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
  // accelerating from zero velocity
  easeInQuint: t => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: t => 1 + (--t) * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,

  easeOutBack: (t) => {
    const c1 = 1.70158;
    const c3 = c1 + 1;

    return 1 + c3 * Math.pow(t - 1, 3) + c1 * Math.pow(t - 1, 2);
  }
}

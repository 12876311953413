import { useWeb3React } from "@web3-react/core"
import { useCallback, useContext, useEffect } from "react"
import { GawdsContractFactory } from "web/lib/contract"
import { NonceContext } from "web/lib/nonce-provider"
import { TrustedForwarder } from "web/types/ethers-contracts"
import useEnvironment from "./useEnvironment"

export default function usePolygonNonceManager() {
    const { account } = useWeb3React()
    const { log } = useEnvironment()
    const  [localNonce, dispatchNonce] = useContext(NonceContext)

    const forwarder = GawdsContractFactory.get("TrustedForwarder") as TrustedForwarder

    const getForwarderNonce = useCallback(async (acct: string): Promise<number> => {
        return (await forwarder.getNonce(acct)).toNumber()
    }, [])

    const fetchLatestNonce = useCallback(async (): Promise<number> => {
        const remoteNonce = await getForwarderNonce(account)
        const currentNonce = localNonce.nonce > remoteNonce ? localNonce.nonce : remoteNonce
        // log('fetchLatestNonce', { remoteNonce, localNonce, currentNonce })

        // Sync the nonce globally
        dispatchNonce(['latest', { nonce: remoteNonce }])

        return currentNonce
    }, [account, localNonce])

    return {
        localNonce,
        fetchLatestNonce,
    }
}